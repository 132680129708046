<template>
  <div class="home">
    <el-card>
      <div slot="header">
        <span>断层扫描</span>
        <el-button type="text" style="margin-left: 20px" @click="$router.go(-1)"> 返回</el-button>

        <el-button type="text" style="margin-left: 20px" @click="updateAudit" v-if="editFlag && form.auditStatus == 2">
          撤回审核</el-button>
      </div>
      <div class="uploadList">
        <el-upload v-if="!editFlag" class="upload-demo" drag :action="$uploadUrl + '/common/upload'"
          :on-success="handleThumbnail" multiple :show-file-list="false" :headers="token">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">{{ $t("add.clickAddPictures") }}</div>
          <!-- <div class="el-upload__text">{{ $t("add.PNGformat") }}</div> -->
        </el-upload>

        <div class="imgList">
          <div class="imgItem" v-for="(item, index) in imgList" :key="index">
            <img :src="item" alt="" />
            <el-button type="text" @click="delImg(index)" v-if="!stop">删除</el-button>
          </div>
        </div>
      </div>
    </el-card>

    <el-form ref="form" :model="form" class="form" style="display: flex; flex-wrap: wrap" :disabled="editFlag">
      <el-card>
        <div slot="header">
          <span>古生物分类信息</span>
        </div>

        <div class="flex wrap jsb clearfix">
          <el-form-item :label="$t('details.resourceName')" prop="resourceName">
            <el-input v-model="form.resourceName" placeholder="请输入资源名称" />
          </el-form-item>
          <el-form-item label="资源名称-英文" prop="resourceNameEn">
            <el-input v-model="form.resourceNameEn" placeholder="请输入资源名称-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.author')" prop="author">
            <el-input v-model="form.author" placeholder="请输入作者" />
          </el-form-item>
          <el-form-item label="作者-英文" prop="authorEn">
            <el-input v-model="form.authorEn" placeholder="请输入作者-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.doi')" prop="referenceId">
            <el-input v-model="form.referenceId" placeholder="请输入文献doi" />
          </el-form-item>
          <el-form-item :label="$t('details.phylum')" prop="phylum">
            <el-input v-model="form.phylum" placeholder="请输入门" />
          </el-form-item>
          <el-form-item label="门-英文" prop="phylumEn">
            <el-input v-model="form.phylumEn" placeholder="请输入门-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.classA')" prop="classA">
            <el-input v-model="form.classA" placeholder="请输入纲" />
          </el-form-item>
          <el-form-item label="纲-英文" prop="classEn">
            <el-input v-model="form.classEn" placeholder="请输入纲-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.orderA')" prop="orderA">
            <el-input v-model="form.orderA" placeholder="请输入目" />
          </el-form-item>
          <el-form-item label="目-英文" prop="orderEn">
            <el-input v-model="form.orderEn" placeholder="请输入目-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.family')" prop="family">
            <el-input v-model="form.family" placeholder="请输入科" />
          </el-form-item>
          <el-form-item label="科-英文" prop="familyEn">
            <el-input v-model="form.familyEn" placeholder="请输入科-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.genus')" prop="genus">
            <el-input v-model="form.genus" placeholder="请输入属" />
          </el-form-item>
          <el-form-item label="属-英文" prop="genusEn">
            <el-input v-model="form.genusEn" placeholder="请输入属-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.species')" prop="species">
            <el-input v-model="form.species" placeholder="请输入种名" />
          </el-form-item>
          <el-form-item label="种名-英文" prop="speciesEn">
            <el-input v-model="form.speciesEn" placeholder="请输入种名-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.Broadcategory')" prop="category">
            <el-input v-model="form.trivialName" placeholder="请输入大类-对应类别门或纲" />
          </el-form-item>
          <el-form-item :label="$t('details.Broadcategory')" prop="category">
            <el-input v-model="form.trivialNameEn" placeholder="请输入大类-对应类别门或纲" />
          </el-form-item>
          <div style="width: 240px"></div>
        </div>
      </el-card>

      <el-card>
        <div slot="header">
          <span>化石标本信息</span>
        </div>
        <div class="flex wrap jsb">
          <el-form-item :label="$t('details.reference')" prop="reference">
            <el-input v-model="form.reference" placeholder="请输入发表文献" />
          </el-form-item>
          <el-form-item label="发表文献英文" prop="referenceEn">
            <el-input v-model="form.referenceEn" placeholder="请输入发表文献英文" />
          </el-form-item>
          <el-form-item :label="$t('details.materials')" prop="materialHorizon">
            <el-input v-model="form.materialHorizon" placeholder="请输入材料" />
          </el-form-item>
          <el-form-item label="材料-英文" prop="materialHorizonEn">
            <el-input v-model="form.materialHorizonEn" placeholder="请输入材料-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.dataSource')" prop="dataSource">
            <el-input v-model="form.dataSource" placeholder="请输入数据来源" />
          </el-form-item>
          <el-form-item label="数据来源-英文" prop="dataSourceEn">
            <el-input v-model="form.dataSourceEn" placeholder="请输入数据来源-英文" />
          </el-form-item>
          <el-form-item label="扫描类型-英文" prop="scannerTypeEn">
            <el-input v-model="form.scannerTypeEn" placeholder="请输入扫描类型-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.resolution')" prop="resolution">
            <el-input v-model="form.resolution" placeholder="请输入解析度" />
          </el-form-item>
          <el-form-item label="解析度-英文" prop="resolutionEn">
            <el-input v-model="form.resolutionEn" placeholder="请输入解析度-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.dataVolume')" prop="dataVolume">
            <el-input v-model="form.dataVolume" placeholder="请输入数据量" />
          </el-form-item>
          <el-form-item :label="$t('details.dataFormat')" prop="dataFormat">
            <el-input v-model="form.dataFormat" placeholder="请输入数据格式" />
          </el-form-item>
          <el-form-item :label="$t('details.dataDescription')" prop="description">
            <el-input v-model="form.description" placeholder="请输入内容" />
          </el-form-item>
          <el-form-item :label="$t('details.keyWords')" prop="keyWords">
            <el-input v-model="form.keyWords" placeholder="请输入关键词" />
          </el-form-item>
          <el-form-item :label="$t('details.funding')" prop="funding">
            <el-input v-model="form.funding" placeholder="请输入资助来源" />
          </el-form-item>

          <el-form-item :label="$t('details.zip')" prop="tomographyZip">
            <el-upload style="width: 280px" class="upload-demo alc flex" :action="$uploadUrl + '/common/upload'"
              :headers="token" :on-success="handleZipSuccess" :file-list="zipList" :on-remove="zipRemove"
              :before-remove="zipbeforeRemove" :multiple="false" :limit="1">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item :label="$t('details.resourcesLink')" prop="funding" style="width: 280px">
            <div>
              <a :href="form.tomographyZip" target="_bank">点击下载</a>
            </div>
          </el-form-item>
        </div>
      </el-card>

      <el-card>
        <div slot="header">
          <span>地质信息</span>
        </div>
        <div class="flex wrap jsb clearfix">
          <el-form-item :label="$t('details.eon')" prop="eon">
            <el-input v-model="form.eon" dd="请输入时代-宙" />
          </el-form-item>
          <el-form-item :label="$t('details.era')" prop="era">
            <el-input v-model="form.era" dd="请输入时代-代" />
          </el-form-item>
          <el-form-item :label="$t('details.period')" prop="period">
            <el-input v-model="form.period" dd="请输入时代-纪" />
          </el-form-item>
          <el-form-item :label="$t('details.epoch')" prop="epoch">
            <el-input v-model="form.epoch" dd="请输入时代-世" />
          </el-form-item>
          <el-form-item :label="$t('details.age')" prop="age">
            <el-input v-model="form.age" dd="请输入时代-期" />
          </el-form-item>
          <el-form-item :label="$t('details.age')" prop="age">
            <el-input v-model="form.ageEn" dd="请输入时代-期" />
          </el-form-item>
          <el-form-item :label="$t('details.ageFrom')" prop="ageFrom">
            <el-input v-model="form.ageFrom" dd="请输入首现年龄/Ma" />
          </el-form-item>
          <el-form-item :label="$t('details.ageTo')" prop="ageTo">
            <el-input v-model="form.ageTo" dd="请输入末现年龄/Ma" />
          </el-form-item>
          <el-form-item :label="$t('details.ageMid')" prop="ageMid">
            <el-input v-model="form.ageMid" dd="请输入平均年龄/Ma" />
          </el-form-item>
        </div>
      </el-card>

      <el-card style="width: 100%">
        <div slot="header">
          <span>地理信息</span>
        </div>
        <div class="flex wrap jsb">
          <el-form-item :label="$t('details.spot')" prop="locality">
            <el-input v-model="form.locality" placeholder="请输入发现地" />
          </el-form-item>
          <el-form-item label="发现地-英文" prop="localityEn">
            <el-input v-model="form.localityEn" placeholder="请输入发现地-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.longitude')" prop="longitude">
            <!-- <el-input v-model="form.longitude" dd="请输入经度" /> -->
            <el-input-number v-model="form.longitude" :precision="9" :step="0.1" :max="10"></el-input-number>
          </el-form-item>
          <el-form-item :label="$t('details.latitude')" prop="latitude">
            <!-- <el-input v-model="form.latitude" dd="请输入纬度" /> -->
            <el-input-number v-model="form.latitude" :precision="9" :step="0.1" :max="10"></el-input-number>
          </el-form-item>
        </div>
      </el-card>
    </el-form>

    <div class="btn" @click="goPublish" v-if="!editFlag">
      {{ $t("add.issue") }}
    </div>
  </div>
</template>

<script>
import ImageApi from "@/api/image/index";
import { getToken } from "@/utils/auth";
import commonApi from "@/api/common/index";

export default {
  name: "myindex",

  data() {
    return {
      form: {},
      imgList: [],
      tokenInfo: "",
      zipList: [],
      token: {},
      editFlag: false,
    };
  },
  computed: {},
  watch: {
    $route(to) {
      if (to.query) {
        this.form = {};
        this.imgList = [];
      }
    },
  },
  methods: {

    async updateAudit() {
      const res = await commonApi.updateAudit({ auditId: this.form.auditId });
      if (res.code == 200) {
        this.editFlag = false;
        this.$route.query.id && this.initImg(this.$route.query.id);
      }
    },
    async initImg(id) {
      const { data: res } = await ImageApi.UserTomographyInfo(id);
      this.form = res;
      this.imgList = this.form.imageUrl.split("&");
      if (this.form.auditStatus == 2) {
        this.editFlag = true;
      }
    },
    handleZipSuccess(res) {
      this.form.tomographyZip = res.url;
    },
    zipbeforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    zipRemove() {
      this.zipList = [];
    },

    async goPublish() {
      if (this.$isObjEqual(this.form)) {
        return this.$message.error("请填写古生物分类信息");
      }
      this.form.imageUrl = this.imgList.join("&");
      if (this.form.tomographyId != null) {
        const res = await ImageApi.editTomography(this.form);
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.imgList = [];
          this.editFlag = false;

          // imageUrl
          this.$confirm("是否提交审核?", "提示", {
            confirmButtonText: "提交审核",
            cancelButtonText: "返回",
            type: "warning",
          })
            .then(() => {
              this.passAudit();
              this.$message({
                type: "success",
                message: "提交成功!",
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
          this.$router.push({
            path: "user",
          });
        }
      } else {
        const res = await ImageApi.addTomography(this.form);
        if (res.code == 200) {
          this.form = {};
          this.$message.success("添加成功");
          this.editFlag = false;
          this.imgList = [];
          this.$router.push({
            path: "/",
          });
        }
      }
    },
    handleThumbnail(res) {
      this.imgList.push(res.url);
    },
    delImg(index) {
      this.imgList.splice(index, 1);
    },
  },
  created() {
    this.token = {
      Authorization: getToken(),
    };
    this.$route.query.id && this.initImg(this.$route.query.id);
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 1400px;
  margin: 0 auto;
  margin-top: 14px;
  padding-bottom: 50px;
  height: auto !important;

  .el-card {
    padding: 0 20px;
    margin-top: 20px;
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .el-input {
      width: 240px;
    }
  }

  .btn {
    width: 572px;
    height: 50px;
    background: #2b82e3;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    line-height: 50px;
    cursor: pointer;
    margin: 35px auto;
  }

  .uploadList {
    display: flex;
    padding-bottom: 50px;

    .imgList {
      display: flex;

      .imgItem {
        width: 312px;
        height: 182px;
        margin-left: 28px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.imgList {
  display: flex;
  flex-wrap: wrap;

  .imgItem {
    width: 312px;
    height: 182px;
    margin-left: 28px;
    margin-bottom: 40px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
